import classNames from "classnames";
import { FreeTrialRole, InitUserRole, postAsyncRequest, useUserState, UUID } from "gx-npm-lib";
import { EyeIcon } from "gx-npm-icons";
import { PopoverMenu, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { MouseEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { InitiativeStateChangeAction } from "../../../app.constants";
import { HomeAppContext } from "../../../app.context";
import { Initiative } from "../../../app.types";
import styles from "./eval-card.styles.module.scss";
import EvalCardFooter from "./eval-card-footer/eval-card-footer.component";
import DeleteDialog from "./delete-dialog/delete-dialog.component";

type EvalItemComponentProps = { initData: Initiative; onInitiativeRemove?: (id: UUID) => void };
const EvalItemComponent = ({ initData, onInitiativeRemove = () => {} }: EvalItemComponentProps) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { templateName, id, name, lastActivity, userRole } = initData;
  const updatedInitName = name || "Untitled eval";

  const isOwner = userRole === InitUserRole.OWNER;
  const isViewOnly = userRole === InitUserRole.VIEWER;
  const { t } = useTranslation();
  const { setShowSnackBar, setSnackbarMessage, setSaveDataErrorStatus } = useContext(HomeAppContext);
  const [isLoading, setIsLoading] = useState(false);

  const { freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  const menuItems = [
    {
      index: 1,
      name: t("Archive"),
      action: InitiativeStateChangeAction.ARCHIVE,
    },
    {
      index: 2,
      name: t("Delete"),
      action: InitiativeStateChangeAction.DELETE,
    },
  ];
  const navigateToOverview = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${id}/overview`);
  };

  const handleInitiativeArchive = async () => {
    setIsLoading(true);
    setSnackbarMessage(`${updatedInitName} was sent to archive.`);
    const response = await postAsyncRequest(`/api/v2/initiatives/${id}/action/archive`);
    if (response.status === 201) {
      setShowSnackBar(true);
      onInitiativeRemove(id);
    } else {
      setSaveDataErrorStatus(true);
    }
    setIsLoading(false);
  };

  const handleMenuOptionClick = async (e: MouseEvent<HTMLButtonElement>) => {
    const eventIndex = e as unknown as number;
    const selectedItem = menuItems?.find(({ index }) => index === eventIndex);
    const { action } = selectedItem || {};
    if (action === InitiativeStateChangeAction.DELETE) {
      setDeleteDialog(true);
    } else if (action === InitiativeStateChangeAction.ARCHIVE) {
      await handleInitiativeArchive();
    }
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    setSnackbarMessage(`${updatedInitName} was sent to trash.`);
    const response = await postAsyncRequest(`/api/v2/initiatives/${id}/action/delete`);
    if (response.status === 201) {
      setShowSnackBar(true);
      setDeleteDialog(false);
      onInitiativeRemove(id);
    } else {
      setSaveDataErrorStatus(true);
    }
    setIsLoading(false);
  };

  let kebabMenuTooltipText = t("Evaluation options");
  if (isTrialUser) {
    kebabMenuTooltipText = t("Archive and deleting is not available in the Gartner BuySmart free trial.");
  } else if (!isOwner) {
    kebabMenuTooltipText = t("This action is only available to the evaluation owner.");
  }
  return (
    <div className={styles.evalCardWrapper}>
      <button className={styles.overviewLink} aria-label={t("click for overview")} onClick={navigateToOverview}>
        <div className={styles.evalCard}>
          <div className={styles.templateWrapper}>
            <TypographyComponent color={"iron"} styling="p4" rootClassName={styles.templateName}>
              {templateName}
            </TypographyComponent>

            {isViewOnly && (
              <div className={classNames(styles.viewOnly, !templateName && styles.noTemplateName)}>
                <EyeIcon width="20" height="18" />
                <TypographyComponent styling="p4" rootClassName={classNames(styles.viewOnlyText)}>
                  {t("View only")}
                </TypographyComponent>
              </div>
            )}
          </div>
          <div
            className={classNames(styles.title, !templateName && styles.noTemplate, isViewOnly && styles.viewOnlyTitle)}
          >
            <TypographyComponent styling="p1" boldness="medium" rootClassName={styles.titleWrap}>
              {name || t("Untitled eval")}
            </TypographyComponent>
          </div>
          <EvalCardFooter products={initData.products} status={initData.status} lastActivity={lastActivity} />
        </div>
      </button>
      <TooltipV2
        data-testid="menu-icon"
        enterNextDelay={300}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0, 5px" } } }}
        title={kebabMenuTooltipText}
      >
        <div className={classNames(styles.stateOptionWrapper)}>
          <PopoverMenu
            disabled={!isOwner || isTrialUser}
            iconType={"kebab"}
            menuItems={menuItems}
            onClick={handleMenuOptionClick}
            rootClassName="gx-initiative-states-item"
            showSelectedOption={false}
            useIconButton={true}
            alignmentType={"left"}
          />
        </div>
      </TooltipV2>
      <DeleteDialog
        displayName={updatedInitName}
        handleClose={() => setDeleteDialog(false)}
        handleConfirm={handleConfirmDelete}
        isLoading={isLoading}
        isOpen={deleteDialog}
      />
    </div>
  );
};

export default EvalItemComponent;
