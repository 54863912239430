import { ProductLogo, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { Fragment, KeyboardEvent, MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { HomeAppContext } from "../../../app.context";
import { navigateToUrl } from "single-spa";
import styles from "./last-viewed-vendors.styles.module.scss";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../../app.constants";
import classNames from "classnames";

const LastViewedVendorsComponent = () => {
  const { t } = useTranslation();
  const { recentProductViews } = useContext(HomeAppContext);
  const captureEvents = useCaptureEventsV2();
  const toMarketProfileProductUrl = (templateId: string, productId: string) =>
    `/s/market/${templateId}/product/${productId}`;
  const handleVendorClick = (
    e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>,
    templateId: string,
    productId: string
  ) => {
    e.preventDefault();
    captureEvents([
      {
        eventType: ClientEvent.HOME_RECENT_VENDOR_CLICKED,
        metaData: {
          templateId: templateId,
          productId: productId,
        },
      },
    ]);
    navigateToUrl(toMarketProfileProductUrl(templateId, productId));
  };

  return (
    <Fragment>
      <TypographyComponent color="iron" boldness="medium" type="p5" rootClassName={styles.sectionTitle}>
        {t("Last viewed vendors")}
      </TypographyComponent>
      <ol aria-label={t("vendor list")} className={styles.listContainer}>
        {recentProductViews.map((productView) => (
          <li key={productView.id} className={styles.vendorListItem}>
            <div className={classNames(styles.vendorTextContainer, styles.hoverLink)}>
              <TooltipV2
                title={productView.name}
                placement="top"
                enterDelay={1500}
                enterNextDelay={1500}
                PopperProps={{
                  modifiers: { offset: { offset: "0, 10px" } },
                }}
                rootClassName={styles.textToolTip}
              >
                <a
                  aria-label={"vendor-link"}
                  className={styles.vendorLink}
                  onKeyDown={(e) => e.key === "Enter" && handleVendorClick(e, productView.templateId, productView.id)}
                  onClick={(e) => handleVendorClick(e, productView.templateId, productView.id)}
                  href={toMarketProfileProductUrl(productView.templateId, productView.id)}
                >
                  <ProductLogo
                    imageWidth="24px"
                    imageHeight="24px"
                    logo={productView.imageLoc}
                    name={productView.name}
                    rootClassName={styles.listIcon}
                  />
                  <TypographyComponent color="coal" boldness="semi" styling="p4" rootClassName={styles.vendorNameText}>
                    {productView.name}
                  </TypographyComponent>
                </a>
              </TooltipV2>
              <span className={styles.breakText} />
              <TypographyComponent color="iron" styling="p5" rootClassName={styles.vendorMarketText}>
                {productView.templateName}
              </TypographyComponent>
            </div>
          </li>
        ))}
      </ol>
    </Fragment>
  );
};

export default LastViewedVendorsComponent;
