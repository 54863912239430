import React, { MouseEvent, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Collapse, Fade } from "@material-ui/core";
import { navigateToUrl } from "single-spa";
import { colorPalette } from "gx-npm-common-styles";
import { IconButton, TypographyComponent } from "gx-npm-ui";
import { postAsyncRequest } from "gx-npm-lib";
import { CheckmarkCircledIcon, CheckmarkDottedCircledOutlineIcon } from "gx-npm-icons";
import { HomeAppContext } from "../../app.context";
import { ChecklistTasks } from "../../app.types";
import styles from "./tasks.styles.module.scss";

const CHECK_ICON_VISIBLE_DURATION_MS = 1000;
const TASK_COLLAPSE_DURATION_MS = 700;
const TASK_FADE_DURATION_MS = 700;

type TaskProps = {
  data: ChecklistTasks;
  showCompletedTasks?: boolean;
};

const Task: React.FC<TaskProps> = ({ data, showCompletedTasks = false }) => {
  const { t } = useTranslation();
  const { checklistTasks, setChecklistTasks, setSaveDataErrorStatus } = useContext(HomeAppContext);

  const [canCollapse, setCanCollapse] = useState(false);
  const [startedAnimation, setStartedAnimation] = useState(false);
  const [finishedAnimation, setFinishedAnimation] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [toDelete, setToDelete] = useState(false);
  const [error, setError] = useState(false);

  const { dueDate, id, initId, initName, isComplete, name, phaseId } = data;

  const hasDueDate = new Date(dueDate).getTime() !== 0;

  useEffect(() => {
    setIsChecked(isComplete);
  }, [isComplete]);

  useEffect(() => {
    if (showCompletedTasks) {
      setToDelete(false);
    }
  }, [showCompletedTasks, toDelete]);

  useEffect(() => {
    if (error) {
      if (showCompletedTasks) {
        setError(false);
        setIsChecked(!isChecked);
      } else {
        setIsChecked(false);
      }
    }
  }, [error, isChecked, setError, showCompletedTasks]);

  useEffect(() => {
    if (showCompletedTasks && startedAnimation && !finishedAnimation) {
      setChecklistTasks((prev) =>
        prev.map((task) =>
          task.initId === initId && task.phaseId === phaseId && task.id === id ? { ...task, isComplete: true } : task
        )
      );

      setStartedAnimation(false);
    }
  }, [checklistTasks, finishedAnimation, id, initId, phaseId, setChecklistTasks, showCompletedTasks, startedAnimation]);

  const handleEventTask = () => {
    navigateToUrl(`/s/evaluation/${initId}/checklist`);
  };

  const handleEventTaskButton = (e: MouseEvent) => {
    e.stopPropagation();

    (async () => {
      if (showCompletedTasks) {
        setIsChecked(!isChecked);
      } else {
        setIsChecked(true);
        setTimeout(() => {
          setStartedAnimation(true);
          setToDelete(true);
        }, CHECK_ICON_VISIBLE_DURATION_MS);
      }

      try {
        const payload = { complete: showCompletedTasks ? !isChecked : true };
        const response = await postAsyncRequest(
          `/api/v2/initiatives/${initId}/checklist/phase/${phaseId}/item/${id}`,
          payload
        );
        if (response.status !== 201) {
          throw new Error();
        }

        if (showCompletedTasks) {
          setChecklistTasks((prev) =>
            prev.map((task) =>
              task.initId === initId && task.phaseId === phaseId && task.id === id
                ? { ...task, isComplete: !task.isComplete }
                : task
            )
          );
        }
      } catch (err) {
        setSaveDataErrorStatus(true);
        setError(true);
      }
    })();
  };

  const updateChecklistTasks = () => {
    setStartedAnimation(false);
    if (error) {
      setError(false);
      setToDelete(false);
      return;
    }
    setChecklistTasks((prev) =>
      prev.map((task) =>
        task.initId === initId && task.phaseId === phaseId && task.id === id ? { ...task, isComplete: true } : task
      )
    );
    setFinishedAnimation(true);
  };

  return (
    <>
      {!finishedAnimation && (
        <Collapse
          className={styles.tableRowWrapper}
          in={canCollapse ? !toDelete : true}
          key={`${initId}-${initName}-${id}-${name}`}
          onExited={() => {
            updateChecklistTasks();
            setCanCollapse(false);
          }}
          timeout={TASK_COLLAPSE_DURATION_MS}
        >
          <Fade
            in={!toDelete}
            onExited={() => {
              setCanCollapse(true);
            }}
            timeout={TASK_FADE_DURATION_MS}
          >
            <div
              aria-label={t("task")}
              className={styles.tableBodyRow}
              role={"button"}
              tabIndex={0}
              onClick={() => {
                handleEventTask();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleEventTask();
                }
              }}
            >
              <div className={classNames(styles.tableBodyColumn, styles.taskNameCol)}>
                <IconButton
                  ariaLabel={isChecked ? t("complete") : t("not complete")}
                  onClick={(e) => handleEventTaskButton(e)}
                >
                  {isChecked ? (
                    <CheckmarkCircledIcon fillPath={colorPalette.status.darkPear.hex} />
                  ) : (
                    <CheckmarkDottedCircledOutlineIcon />
                  )}
                </IconButton>
                <TypographyComponent boldness={"medium"} color={"carbon"} rootClassName={styles.taskName} type={"p3"}>
                  {name}
                </TypographyComponent>
              </div>
              <div className={classNames(styles.tableBodyColumn, styles.taskInitNameCol)}>
                <TypographyComponent boldness={"regular"} color={"coal"} rootClassName={styles.initName} type={"p4"}>
                  {initName}
                </TypographyComponent>
              </div>
              <div className={classNames(styles.tableBodyColumn, styles.taskDueDateCol)}>
                <TypographyComponent boldness={"regular"} color={"coal"} type={"p4"}>
                  {hasDueDate && dayjs(dueDate).format("MMM DD, YYYY")}
                </TypographyComponent>
              </div>
            </div>
          </Fade>
        </Collapse>
      )}
    </>
  );
};

export default Task;
