import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Switch, TypographyComponent } from "gx-npm-ui";
import NoTasks from "./no-tasks/no-tasks.component";
import { HomeAppContext } from "../../app.context";
import { ChecklistTasks } from "../../app.types";
import TasksIcon from "./tasks.icon";
import styles from "./tasks.styles.module.scss";
import Task from "./task.component";

const MAX_TASKS_DISPLAYED = 9;

const Tasks = () => {
  const { t } = useTranslation();
  const { initiatives, checklistTasks } = useContext(HomeAppContext);
  const [completedAllTasks, setCompletedAllTasks] = useState<boolean>(false);
  const [displayedChecklistTasks, setDisplayedChecklistTasks] = useState<Array<ChecklistTasks>>([]);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [shadow, setShadow] = useState(false);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!initiatives.length) {
      return;
    }

    const scrollParent = scrollContainer.current;

    const toggleShadows = () => {
      const topPos = scrollRef.current?.parentElement?.scrollTop || 0;
      if (topPos > 0) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };

    scrollParent?.addEventListener("scroll", toggleShadows);

    return () => {
      scrollParent?.removeEventListener("scroll", toggleShadows);
    };
  }, [checklistTasks, initiatives, scrollContainer]);

  useEffect(() => {
    if (!checklistTasks || !checklistTasks.length) {
      return;
    }

    if (!showCompletedTasks) {
      const filteredTasks = checklistTasks.filter((task) => !task.isComplete);
      setDisplayedChecklistTasks(filteredTasks);
    } else {
      setDisplayedChecklistTasks(checklistTasks);
    }
  }, [checklistTasks, setDisplayedChecklistTasks, showCompletedTasks]);

  useEffect(() => {
    if (!checklistTasks || !checklistTasks.length) {
      return;
    }

    setCompletedAllTasks(checklistTasks.filter((task) => task.isComplete).length === checklistTasks.length);
  }, [checklistTasks, setCompletedAllTasks]);

  const hasTasks = checklistTasks && checklistTasks.length > 0;
  const isTableEmpty = !hasTasks || (!showCompletedTasks && completedAllTasks);

  return (
    <section aria-label={t("My tasks")}>
      <div className={styles.header}>
        <div className={styles.headerIcon}>
          <TasksIcon />
        </div>
        <TypographyComponent color="yam" boldness="medium" styling="p3">
          {t("My tasks")}
        </TypographyComponent>
      </div>
      <div className={classNames(!initiatives.length && styles.container)}>
        {!initiatives.length && <NoTasks />}
        {initiatives.length > 0 && (
          <div aria-label={t("assigned tasks")} className={styles.containerTasks}>
            <div
              aria-label={showCompletedTasks ? t("showing completed tasks") : t("hiding completed tasks")}
              className={styles.switchCompletedTasksWrapper}
            >
              <Switch onToggle={() => setShowCompletedTasks((prevToggle) => !prevToggle)} />
              <TypographyComponent boldness={"regular"} color={"coal"} type={"p3"}>
                {t("Show completed tasks")}
              </TypographyComponent>
            </div>
            <div
              className={classNames(
                styles.table,
                !isTableEmpty && displayedChecklistTasks.length > MAX_TASKS_DISPLAYED && styles.fade
              )}
              role={"table"}
            >
              <div className={classNames(styles.tableHeader, shadow && styles.scrollingShadow)}>
                <div className={styles.tableHeaderRow}>
                  <div className={classNames(styles.tableHeaderColumn, styles.headerChecklist)}>
                    <TypographyComponent boldness={"medium"} color={"iron"} type={"p4"}>
                      {t("Checklist items")}
                    </TypographyComponent>
                  </div>
                  <div className={classNames(styles.tableHeaderColumn, styles.headerEvaluation)}>
                    <TypographyComponent boldness={"medium"} color={"iron"} type={"p4"}>
                      {t("Evaluation")}
                    </TypographyComponent>
                  </div>
                  <div className={styles.tableHeaderColumn}>
                    <TypographyComponent boldness={"medium"} color={"iron"} type={"p4"}>
                      {t("Due date")}
                    </TypographyComponent>
                  </div>
                </div>
              </div>
              <div
                aria-label={t("tasks")}
                className={classNames(styles.tableBody, !isTableEmpty && styles.showScrollBar)}
                ref={scrollContainer}
              >
                <div ref={scrollRef}>
                  {!hasTasks && (
                    <div className={classNames(styles.tableBodyRow, styles.noTasks)}>
                      <div className={styles.tableBodyColumn}>
                        <TypographyComponent boldness={"regular"} color={"iron"} type={"p3"}>
                          {t("There are no tasks assigned to you.")}
                        </TypographyComponent>
                      </div>
                    </div>
                  )}

                  {hasTasks && !showCompletedTasks && completedAllTasks && (
                    <div className={classNames(styles.tableBodyRow, styles.noTasks)}>
                      <div className={styles.tableBodyColumn}>
                        <TypographyComponent boldness={"regular"} color={"iron"} type={"p3"}>
                          {t("All tasks are currently complete.")}
                        </TypographyComponent>
                      </div>
                    </div>
                  )}

                  {hasTasks &&
                    (showCompletedTasks || !completedAllTasks) &&
                    displayedChecklistTasks.map((task) => {
                      const { id, initId, initName, name } = task;

                      return (
                        <Task
                          data={task}
                          key={`${initId}-${initName}-${id}-${name}`}
                          showCompletedTasks={showCompletedTasks}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Tasks;
