import { KeyboardEvent, MouseEvent, useContext, useState } from "react";
import { Fade } from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import EvaluationsIcon from "./evaluations.icon";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import NoEvaluationsComponent from "./no-evaluations/no-evaluations.component";
import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import styles from "./evaluations.styles.module.scss";
import { ClientEvent } from "../../app.constants";
import { navigateToUrl } from "single-spa";
import { HomeAppContext } from "../../app.context";
import EvalCardComponent from "./eval-card/eval-card.component";

const EvaluationsComponent = () => {
  const { t } = useTranslation();
  const [collapseInitId, setCollapseInitId] = useState<UUID>("");
  const [fadeInitId, setFadeInitId] = useState<UUID>("");
  const { initiatives, setInitiatives } = useContext(HomeAppContext);

  const collapsingTimer = 300;
  const cleanupTimer = 600;

  const captureEvents = useCaptureEventsV2();
  const handleViewEvaluationsClick = (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    captureEvents([
      {
        eventType: ClientEvent.HOME_ACTIVE_EVALUATIONS_CLICKED,
      },
    ]);
    navigateToUrl("/s/evaluations");
  };

  const handleRemoveAnimation = (id: UUID) => {
    // Fade out the initiative (by setting id of init)
    setFadeInitId(id);

    // Collapse the initiative (by setting id of init) if there is more than 1 initiative
    if (initiatives.length > 1) {
      setTimeout(() => {
        setCollapseInitId(id);

        // Remove the initiative from the context
        setTimeout(() => {
          setInitiatives((prev) => prev.reduce((acc, curr) => (curr.id === id ? acc : [...acc, curr]), []));
        }, cleanupTimer);
      }, collapsingTimer);
    } else {
      setInitiatives([]);
    }
  };

  return (
    <section className={styles.myInitiatives} aria-label={t("My active evaluations")}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <div className={styles.headerIcon}>
            <EvaluationsIcon />
          </div>
          <TypographyComponent color="yam" boldness="medium" styling="p3">
            {t("My active evaluations")}
          </TypographyComponent>
        </div>
        <div>
          {initiatives.length > 0 && <TextLink text={t("View evaluations")} onClick={handleViewEvaluationsClick} />}
        </div>
      </div>

      <div className={classNames(styles.initiatives, initiatives.length > 3 && styles.fullHeight)}>
        {initiatives.length > 0 &&
          initiatives.slice(0, 3).map((initiative) => (
            <div
              key={initiative.id}
              className={classNames(styles.initiative, collapseInitId === initiative.id && styles.removing)}
            >
              <Fade in={fadeInitId !== initiative.id}>
                <div>
                  <EvalCardComponent onInitiativeRemove={handleRemoveAnimation} initData={initiative} />
                </div>
              </Fade>
            </div>
          ))}
        <Fade in={!initiatives.length}>
          <div className={classNames(initiatives.length && styles.hide)}>
            <NoEvaluationsComponent />
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default EvaluationsComponent;
