import { Fragment } from "react";
import { Button, ButtonLoader, Dialog, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";

import styles from "./delete-dialog.styles.module.scss";

type DeleteDialogProps = {
  displayName?: string;
  handleClose?: () => void;
  handleConfirm?: () => void;
  isLoading?: boolean;
  isOpen?: boolean;
};

const DeleteDialog = ({
  displayName = "",
  handleClose = () => {},
  handleConfirm = () => {},
  isLoading = false,
  isOpen = false,
}: DeleteDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      handleClose={() => handleClose()}
      title={t("Delete evaluation")}
      body={
        <Fragment>
          <TypographyComponent styling="p3" rootClassName={styles.bodyText}>
            <TypographyComponent>{t("Are you sure you want to delete")}</TypographyComponent>
            <TypographyComponent boldness="medium">{` ${displayName}`}</TypographyComponent>
            <TypographyComponent>
              {t("? You can always restore this evaluation if you change your mind.")}
            </TypographyComponent>
          </TypographyComponent>
        </Fragment>
      }
      footer={
        <div className={styles.cancelBtn}>
          {!!handleClose && (
            <Button onClick={() => handleClose()} rootClassName="btn-tertiary">
              {t("CANCEL")}
            </Button>
          )}
          {!!handleConfirm && (
            <div className={styles.confirmBtn}>
              <ButtonLoader
                btnClass={"primary-destructive-btn"}
                disabled={isLoading}
                handleButtonClick={() => handleConfirm()}
                isLoading={isLoading}
              >
                {t("DELETE")}
              </ButtonLoader>
            </div>
          )}
        </div>
      }
    />
  );
};

export default DeleteDialog;
