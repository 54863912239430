import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import { Button, TypographyComponent } from "gx-npm-ui";
import styles from "./ftux-banner.styles.module.scss";
import ftuxHero from "./ftux-hero.svg";
import ftuxBackground from "./ftux-background.svg";

const FTUXBanner = () => {
  const { t } = useTranslation();

  return (
    <section aria-label={t("First time user experience")} className={styles.root}>
      <div className={styles.ftuxBackground} style={{ background: `url(${ftuxBackground}) no-repeat -240px 0px` }} />
      <div className={styles.body}>
        <div className={styles.main}>
          <TypographyComponent color={"gartnerBlue"} styling={"h3"} boldness={"medium"} rootClassName={styles.title}>
            {t("Discover the right market to kickstart your evaluation")}
          </TypographyComponent>
          <TypographyComponent color={"coal"} styling={"p3"} boldness={"medium"} rootClassName={styles.description}>
            {t("Using Gartner's industry leading insights, find the market that fits your team's technology needs.")}
          </TypographyComponent>
          <Button
            onClick={() => {
              navigateToUrl("/s/markets");
            }}
            rootClassName={"btn-primary"}
          >
            {t("Browse markets")}
          </Button>
        </div>
        <div className={styles.heroContainer}>
          <img
            alt={t("Discover the right market to kickstart your evaluation")}
            className={styles.hero}
            src={ftuxHero}
          />
        </div>
      </div>
    </section>
  );
};

export default FTUXBanner;
